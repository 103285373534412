<template>
  <AuctionListItemInfoElement
    v-if="accessToAuction.statusCode === 403"
    icon="material-symbols:lock"
    class="text-red"
  >
    <span class="text-sm"> {{ $t('auction-list.no-access') }} </span>
  </AuctionListItemInfoElement>
</template>

<script setup lang="ts">
import type { DisplayingAuction } from '@autobid/ui/types/components/AuctionsData'
import { useAccessToAuction } from '@autobid/ui/composables/useAccessToAuction'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import AuctionListItemInfoElement from './AuctionListItemInfoElement.vue'

const { restrictions, appId } = inject<DisplayingAuction>('auction')

const { isAuthed } = useAutobidAuth()
const accessToAuction = useAccessToAuction({
  restrictions,
  appId,
  isAuthed
})
</script>
